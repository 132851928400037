import { request } from "@/utils/request"

/**
 * 
 *首页统计信息 ___ 进件统计
 */

export function incomStatistics() {
    return request({
        url: `/api/manage/incomStatistics/info`,
        method: 'post'
    })
}


// 清除进件统计缓存
export function clearIncom() {
    return request({
        url: `/api/manage/incomStatistics/clear`,
        method: 'post'
    })
}