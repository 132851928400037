import { request } from "@/utils/request"

/**
 * 
 * 用户列表接口
 */

//用户列表
export function UserList(data, pagenum, size) {
    return request({
        url: `/api/user/user/areaList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}

//新增用户
export function AddUser(data) {
    return request({
        url: `/api/user/user/add`,
        method: 'post',
        data
    })
} 

//删除用户
export function DeleteUser(data,id) {
    return request({
        url: `/api/user/user/delete/${id}`,
        method: 'delete',
        data
    })
} 

//更新用户
export function changeUser(data,id) {
    return request({
        url: `/api/user/user/update/${id}`,
        method: 'put',
        data
    })
} 

//绑定角色
export function bindRole(data) {
    return request({
        url: `/api/user/user/bindRole`,
        method: 'post',
        data
    })
} 

//解绑角色
export function unBindRole(data) {
    return request({
        url: `/api/user/user/unBindRole`,
        method: 'post',
        data
    })
} 

//查询用户__数据源权限(出资人)
export function getBindUserSouce(data) {
    return request({
        url: `/api/user/user/getBindDataSouce`,
        method: 'post',
        data
    })
}

//绑定用户__数据源权限(出资人)
export function bindUserSouce(data) {
    return request({
        url: `/api/user/user/bindDataSouce`,
        method: 'post',
        data
    })
} 