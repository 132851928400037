<template>
  <div>
    <!-- 一级菜单 -->
    <el-menu-item :index="`/${item.name}`" v-if="item.role && (!item.children || item.children.length == 0)">
      <i :class="item.icon"></i>
      <span slot="title">{{item.title}}</span>
    </el-menu-item>

    <!-- 二级菜单 -->
    <el-submenu v-else-if="item.role" index="setting">
      <template slot="title">
        <i :class="item.icon"></i>
        <span slot="title">{{item.title}}</span>
      </template>
      <AsideItem v-for="child in item.children" :key="child.name" :item="child" />
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: "AsideItem",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.iconfont {
  font-size: 18px;
  margin-right: 9px;
  margin-left: 3px;
}

.el-menu--collapse > div > .el-submenu > .el-submenu__title span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
.el-menu--collapse > div > .el-submenu /deep/.el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
</style>
