<template>
  <div id="dashboard">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2"></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="card-box">
      <div class="card-title">
        <span>上月进件转化</span>
        <el-button v-no-more-click round size="small" icon="el-icon-refresh" @click="refreshIncom()">刷新</el-button>
      </div>
      <el-row :gutter="40">
        <el-col :span="6">
          <el-skeleton :loading="loading" animated>
            <template slot="template">
              <div class="el-card-skeleton">
                <el-skeleton-item variant="image" style="width: 50px; height: 50px" />
                <div class="el-card-skeleton-text">
                  <el-skeleton-item variant="h3" style="width: 60%" />
                  <el-skeleton-item variant="text" style="margin-top: 18px; width: 30%" />
                </div>
              </div>
            </template>
            <template>
              <el-card class="box-card">
                <div class="icon-big">
                  <i class="el-icon-sold-out"></i>
                </div>
                <div class="statistics">
                  <div class="title">进件量</div>
                  <div class="numbers">{{ count }}</div>
                </div>
              </el-card>
            </template>
          </el-skeleton>
        </el-col>
        <el-col :span="6">
          <el-skeleton :loading="loading" animated :rows="3">
            <template slot="template">
              <div class="el-card-skeleton">
                <el-skeleton-item variant="image" style="width: 50px; height: 50px" />
                <div class="el-card-skeleton-text">
                  <el-skeleton-item variant="h3" style="width: 60%" />
                  <el-skeleton-item variant="text" style="margin-top: 18px; width: 30%" />
                </div>
              </div>
            </template>
            <template>
              <el-card class="box-card">
                <div class="icon-big">
                  <i class="el-icon-s-check"></i>
                </div>
                <div class="statistics">
                  <div class="title">审批进件量</div>
                  <div class="numbers">{{ approveCount }}</div>
                </div>
              </el-card>
            </template>
          </el-skeleton>
        </el-col>
        <el-col :span="6">
          <el-skeleton :loading="loading" animated :rows="3">
            <template slot="template">
              <div class="el-card-skeleton">
                <el-skeleton-item variant="image" style="width: 50px; height: 50px" />
                <div class="el-card-skeleton-text">
                  <el-skeleton-item variant="h3" style="width: 60%" />
                  <el-skeleton-item variant="text" style="margin-top: 18px; width: 30%" />
                </div>
              </div>
            </template>
            <template>
              <el-card class="box-card">
                <div class="icon-big">
                  <i class="el-icon-sell"></i>
                </div>
                <div class="statistics">
                  <div class="title">投放量</div>
                  <div class="numbers">{{ investCount }}</div>
                </div>
              </el-card>
            </template>
          </el-skeleton>
        </el-col>
        <el-col :span="6">
          <el-skeleton :loading="loading" animated :rows="3">
            <template slot="template">
              <div class="el-card-skeleton">
                <el-skeleton-item variant="image" style="width: 50px; height: 50px" />
                <div class="el-card-skeleton-text">
                  <el-skeleton-item variant="h3" style="width: 60%" />
                  <el-skeleton-item variant="text" style="margin-top: 18px; width: 30%" />
                </div>
              </div>
            </template>
            <template>
              <el-card class="box-card">
                <div class="icon-big">
                  <i class="el-icon-coin"></i>
                </div>
                <div class="statistics">
                  <div class="title">投放金额(元)</div>
                  <div class="numbers">{{ investAmount }}</div>
                </div>
              </el-card>
            </template>
          </el-skeleton>
        </el-col>
      </el-row>
    </div>
    <div class="card-box">
      <div class="card-title">申请城市明细</div>
      <el-row :gutter="20">
        <el-col>
          <el-card class="box-card">
            <el-table :data="tableData" style="width: 100%" border stripe
              :header-cell-style="{ color: '#333', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900, backgroundColor: '#eff3f8' }">
              <el-table-column type="index" label="序" width="50" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="city" label="申请城市" align="center"> </el-table-column>
              <el-table-column prop="count" label="进件量" align="center"> </el-table-column>
              <el-table-column prop="approveCount" label="审批通过量" align="center"> </el-table-column>
              <el-table-column prop="investCount" label="投放量" align="center"> </el-table-column>
              <el-table-column prop="investAmount" label="投放金额（元）" align="center"> </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
    
<script>
import { incomStatistics, clearIncom } from "@/api/dashboard.js";

export default {
  name: "",
  data() {
    return {
      count: "", // 进件量
      approveCount: "", // 审批通过量
      investCount: "", // 投放量
      investAmount: "", // 投放金额
      tableData: [],
      loading: false,
    };
  },
  mounted() {
    this.getIncomStatistics();
  },
  methods: {
    // 获取进件统计量
    getIncomStatistics(click) {
      this.loading = true;
      incomStatistics().then((res) => {
        const { data } = res;
        this.count = Number(data.count).toLocaleString();
        this.approveCount = Number(data.approveCount).toLocaleString();
        this.investCount = Number(data.investCount).toLocaleString();
        this.investAmount = Number(data.investAmount).toLocaleString();
        const { list } = data;
        list.forEach((item) => {
          item.investAmount = Number(item.investAmount).toLocaleString();
        });
        this.tableData = list;
        this.loading = false;
        if (click) this.$message.success("刷新成功");
      });
    },
    async refreshIncom() {
      this.loading = true;
      await clearIncom(); // 清除统计缓存
      this.getIncomStatistics(true); // 重新获取统计量
    },
  },
};
</script>
    
<style lang='less' scoped>
#dashboard {
  padding: 60px 20px 20px 20px;

  .card-title {
    text-align: left;
    font-weight: 600;
    padding: 16px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-box {
    color: #676a6c;
    background-color: #eeeeee75;
    border-radius: 20px;

    .box-card {
      /deep/ .el-card__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 1.5em;
      }

      .icon-big {
        font-size: 50px;
        color: #2c93fa;
      }
      .statistics {
        text-align: end;
        padding-right: 0.5em;
        .title {
          font-weight: 600;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.45);
          font-size: 16px;
          margin-top: 6px;
          margin-bottom: 6px;
          letter-spacing: 1px;
        }
        .numbers {
          font-size: 22px;
          line-height: 26px;
          font-weight: 600;
          word-break: break-all;
          padding-right: 2px;
        }
      }
    }
  }

  // 骨架屏样式
  .el-card-skeleton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1.5em;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 106px;
    box-sizing: border-box;
  }

  .el-card-skeleton-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>